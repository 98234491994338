import { clientOnly } from "../components/common/isClient"

const gatsbyUserKey = "gatsbyUser"
type UsernamePassword = {
  username: string
  password: string
}
type User = {
  username: string
  [key: string]: string
}

export const safeParse = (value: string | null, defaultValue = {}) =>
  value ? JSON.parse(value) : defaultValue

export const getUser = clientOnly(() =>
  safeParse(window.sessionStorage.getItem(gatsbyUserKey))
)

const setUser = (user: User | {}) =>
  window.sessionStorage.setItem(gatsbyUserKey, JSON.stringify(user))

const setAccessToken = (token: string) =>
  window.localStorage.setItem("gatsbyAccessToken", JSON.stringify(token))

export const getAccessToken = () =>
  safeParse(window.localStorage.getItem("gatsbyAccessToken"), "")

const validate = async ({ username, password }: UsernamePassword) => {
  const res = await fetch("https://sso.jiwai.win/auth/login", {
    method: "POST",
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })

  return await res.json()
}

export const getProfile = async () => {
  const res = await fetch("https://sso.jiwai.win/profile", {
    method: "GET",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })

  if (res.status === 401) {
    throw res
  }

  return await res.json()
}

export const handleLogin = async ({ username, password }: UsernamePassword) => {
  const res = await validate({ username, password })
  setAccessToken(res.access_token)
  setUser(await getProfile())
}

export const isLoggedIn = () => {
  const user = getUser()

  console.log("user is ", user)

  return !!user && !!user.sub
}

export const logout = (callback: Function = () => ({})) => {
  setAccessToken("")
  setUser({})
  callback()
}
