import React from "react"
import { Layout as AntDLayout } from "antd"
import { connect } from "react-redux"

const { Header, Content } = AntDLayout

import NavBar from "./nav-bar"
import Footer from "./footer"

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Layout = ({ children }: any) => (
  <AntDLayout>
    <Header style={{ position: "fixed", zIndex: 1, width: "100%" }}>
      <NavBar />
    </Header>
    <Content style={{ padding: "0 50px", marginTop: 64 }}>{children}</Content>
    <Footer />
  </AntDLayout>
)

export default connect(
  () => ({}),
  () => ({})
)(Layout)
