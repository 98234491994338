import React from "react"
import { Link, navigate } from "gatsby"
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { getUser, isLoggedIn, logout } from "../services/auth"
import { Menu } from "antd"

// eslint-disable-next-line react/display-name
export default () => {
  const content = { message: "", login: true }
  if (isLoggedIn()) {
    content.message = `Hello, ${getUser().username}`
  } else {
    content.message = "You are not logged in"
  }
  return (
    <>
      <div
        className="logo"
        style={{
          width: "240px",
          float: "left",
          color: "white",
          height: "31px",
        }}
      >
        <span>{content.message}</span>
      </div>
      <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={["0"]}
        style={{ lineHeight: "64px" }}
      >
        <Menu.Item key="0">
          <Link to="/">Home</Link>
        </Menu.Item>
        <Menu.Item key="1">
          <Link to="/app/profile">Profile</Link>
        </Menu.Item>
        {isLoggedIn() && (
          <Menu.Item key="3">
            <a
              href="/"
              onClick={event => {
                event.preventDefault()
                logout(() => navigate(`/app/login`))
              }}
            >
              Logout
            </a>
          </Menu.Item>
        )}
      </Menu>
    </>
  )
}
